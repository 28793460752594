<template>
  <div>
    <el-card class="box-card" v-show="showCard == 1">

      <head-layout
            :head-btn-options="headBtnOptions"
            :head-title="$t('cip.dc.projectlist.title.name')"
            @head-add="AddRw"

          >
          </head-layout>

       <div style="height: 75vh;width: 100%;background-color: #fff;overflow-y: scroll;">



      <div class="listBox">


        <div v-for="item in DictList" :key="item.id" class="box1">
          <img style="width: 35px;height: 35px;" src="./img/projects.png" alt="">
          <div style="margin-left: 15px;">
            <div style="font-size: 16px;margin: 5px;">{{ item.name }}</div>
            <div style="font-size: 16px; color: darkgray;margin: 5px;">{{ item.zh_name }}</div>
            <div>
              <span class="textbt" @click="MRunflow(item)">{{ $t('cip.dc.projectlist.listedit.dispatch') }} &nbsp;</span>
              <span class="textbt" @click="MSee(item)">{{ $t('cip.dc.projectlist.listedit.see') }} &nbsp;</span>
              <span class="textbt" @click="Mfabu(item)">{{ $t('cip.dc.projectlist.listedit.release') }} &nbsp;</span>
              <span class="textbt" @click="Mrwgl(item)">{{ $t('cip.dc.projectlist.listedit.TaskManagement') }} &nbsp;</span>
              <span class="textbt" @click="MEdit(item)">{{ $t('cip.dc.projectlist.listedit.edit') }} &nbsp;</span>


              <el-popconfirm confirm-button-text='Yes' cancel-button-text='No' icon="el-icon-info" icon-color="red"
                :title="$t('cip.dc.projectlist.msg.msg1')"
                 @confirm="DelTask(item)">
                <span class="textbt" slot="reference">{{ $t('cip.dc.projectlist.listedit.delete') }} &nbsp;</span>
              </el-popconfirm>

            </div>
          </div>

        </div>




      </div>

    </div>
      <!-- <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination> -->
      <el-dialog :title=dialog1Title2 :visible.sync="dialog1" width="80%" :before-close="handleClose" @close="close1">

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
          <el-form-item :label="$t('cip.dc.projectlist.seetabs.editlabel.Englishname')" prop="name">
            <el-input :disabled="DisName" v-model="ruleForm.name"></el-input>
          </el-form-item>

          <el-form-item :label="$t('cip.dc.projectlist.seetabs.editlabel.Chinesename')" prop="zh_name">
            <el-input v-model="ruleForm.zh_name"></el-input>
          </el-form-item>



          <el-form-item :label="$t('cip.dc.projectlist.seetabs.editlabel.Contentdescription')" prop="content">
            <el-input type="textarea" v-model="ruleForm.content"></el-input>
          </el-form-item>

        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog1 = false">{{ $t('cancelText') }} </el-button>
          <el-button style="margin-left: 20px;" type="primary" @click="submitForm('ruleForm')">{{ $t('submitText') }}</el-button>
        </span>
      </el-dialog>

      <el-dialog :title="$t('cip.dc.projectlist.seetabs.InformationService')" :visible.sync="dialog2" width="60%" :before-close="handleClose">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="$t('cip.dc.projectlist.seetabs.flowChart')" name="first">
            <g2></g2>
          </el-tab-pane>

          <el-tab-pane :label="$t('cip.dc.projectlist.seetabs.taskList')" name="second">
            <el-table :data="Seedata.resFetchexecflow.nodes" border style="width: 100%"
              :default-sort="{ prop: 'startTime', }">
              <el-table-column prop="nestedId" :label="$t('cip.dc.projectlist.seetabs.taskListlabel.name')">
              </el-table-column>
              <el-table-column prop="type" :label="$t('cip.dc.projectlist.seetabs.taskListlabel.type')">
              </el-table-column>
              <!-- <el-table-column prop="address" label="时间轴">
                </el-table-column> -->

              <el-table-column prop="startTime"  :label="$t('cip.dc.projectlist.seetabs.taskListlabel.startTime')">

                <template slot-scope="scope">{{ new Date(scope.row.startTime).toLocaleString() }}</template>

              </el-table-column>
              <el-table-column prop="endTime" :label="$t('cip.dc.projectlist.seetabs.taskListlabel.Endtime')">
                <template slot-scope="scope">{{ new Date(scope.row.endTime).toLocaleString() }}</template>
              </el-table-column>
              <el-table-column prop="address" :label="$t('cip.dc.projectlist.seetabs.taskListlabel.timeConsuming')">
                <template slot-scope="scope">{{
                  (new Date(scope.row.endTime) - new Date(scope.row.startTime)  )/1000 + "s"
                    }}</template>
              </el-table-column>
              <el-table-column prop="status" :label="$t('cip.dc.projectlist.seetabs.taskListlabel.state')">
              </el-table-column>
              <el-table-column :label="$t('cip.dc.projectlist.seetabs.taskListlabel.details')">
                <template slot-scope="scope"> <el-button type="text" @click="dialog3 = true">{{$t('cip.dc.projectlist.seetabs.taskListlabel.details')}}</el-button>
                </template>

              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('cip.dc.projectlist.seetabs.ProcessLog')" name="third">


            <div style="white-space: pre-line;
    "> {{ Seedata.resLog.data }} </div>

          </el-tab-pane>


          <el-dialog :title="$t('cip.dc.projectlist.seetabs.Joblog')" :visible.sync="dialog3" width="60%" :append-to-body="true">
            <div style="white-space: pre-line;
    "> {{ Seedata.resLog.data }} </div>

            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialog3 = false">{{$t('cip.dc.projectlist.btn.sure')}}</el-button>
            </span>
          </el-dialog>

        </el-tabs>



        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog2 = false">{{$t('cip.dc.projectlist.btn.close')}}</el-button>

        </span>
      </el-dialog>
    </el-card>


    <el-card v-show="showCard == 2">
      <div>

        <gg></gg>
      </div>

    </el-card>

</div>
</template>

<script>
import { getScriptDictList, runFlow, flowDetail, releaseProjectZip, AddDirectory, DelTask, TaskGl } from "@/api/dataAcquisition";

import gg from "./src/components/G6Editor/index.vue"
import g2 from "./p2.vue"
import eventBus from "./src/utils/eventBus"
import HeadLayout from "@/views/components/layout/head-layout"
export default {
  name: "projectList",
  data() {
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.addBtn'),
        emit: "head-add",
        type: "button",
        remark: '',
        icon: ""
      }
      ],
      DictList: [],//列表
      dialog1: false,
      dialog1Title: "新建项目",
      dialog1Title2:"",
      dialog2: false,
      dialog3: false,
      DisName: false,
      Cg6: {},
      activeName: "first",
      ruleForm: {
        name: "",
        zh_name: "",
        content: "",
      },
      Seedata: {
        resFetchexecflow: {
          nodes: {}
        },
        resLog: {
          data: ""
        }
      },
      showCard: 1,
      rules: {
        name: [
          { required: true, message: this.$t('cip.dc.projectlist.seetabs.addrule.ename'), trigger: 'blur' },
        ],
        zh_name: [
          { required: true, message: this.$t('cip.dc.projectlist.seetabs.addrule.cname'), trigger: 'blur' },
        ],
        content: [
          { required: true, message: this.$t('cip.dc.projectlist.seetabs.addrule.text'), trigger: 'blur' },
        ],

      }
    }
  },
  components: {
    gg, g2,HeadLayout,
  },
  mounted() {
    this.MgetScriptDictList()
    this.dialog1Title2 = this.$t('cip.dc.projectlist.seetabs.editlabel.NewProject')
    this.$eventBus.$on('CloseG6', (res) => {
      this.showCard = 1
    })


  },
  methods: {


    // JS 计算两个时间戳相差年月日时分秒
    calculateDiffTime(startTime, endTime, type) {
      var runTime = parseInt(endTime - startTime)
      var year = Math.floor(runTime / 86400 / 365)
      runTime = runTime % (86400 * 365)
      var month = Math.floor(runTime / 86400 / 30)
      runTime = runTime % (86400 * 30)
      var day = Math.floor(runTime / 86400)
      runTime = runTime % 86400
      var hour = Math.floor(runTime / 3600)
      runTime = runTime % 3600
      var minute = Math.floor(runTime / 60)
      runTime = runTime % 60
      var second = runTime
      if (type === 1) { // 返回相差年数
        return year + '年'
      } else if (type === 2) { // 返回相差年数月数
        return year + '年' + month + '月'
      } else if (type === 3) { // 返回相差年数月数天数
        return year + '年' + month + '月' + day + '日'
      } else { // 返回相差年数月数天数时分秒
        return year + '年' + month + '月' + day + '日' + hour + '时' + minute + '分' + second + '秒'
      }
    },
    handleClick(e) {
      console.log(e.name);
      if (e == "first") {
        // this.$eventBus.$emit('sendG6',this.Cg6)
      }
    },
    //任务管理
    Mrwgl(e) {
      this.showCard = 2
      TaskGl({ id: e.id }).then(res => {
        if (res.status == 200) {
          this.$eventBus.$emit('eventNameq', res)

        }
      })
    },
    //获取列表
    MgetScriptDictList() {
      getScriptDictList({ perPage: 1, startIndex: 1 }).then(res => {
        if (res.status == 200) {
          this.DictList = res.data.data
        }
      })
    },
    //新增
    AddRw() {
      this.dialog1 = true
      this.dialog1Title = "新建项目"
      this.dialog1Title2 = this.$t('cip.dc.projectlist.seetabs.editlabel.NewProject')
      this.DisName = false
    },

    close1() {
      this.ruleForm = {
        name: "",
        zh_name: "",
        content: "",
      }
      this.$refs["ruleForm"].clearValidate();
    },

    MEdit(e) {
      this.dialog1 = true
      this.dialog1Title = "编辑项目"
      this.dialog1Title2 = this.$t('cip.dc.projectlist.seetabs.editlabel.EditItem')
      this.ruleForm = e
      this.DisName = true
    },
    //删除任务
    DelTask(e) {
      DelTask({ id: e.id }).then(res => {
        if (res.status == 200) {
          this.MgetScriptDictList()
        }
      })
    },

    //新增提交验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialog1Title == "新建项目") {
            AddDirectory({ content: this.ruleForm.content, execid: "", id: null, name: this.ruleForm.name, objectJson: "", pid: 0, zh_name: this.ruleForm.zh_name }).then(res => {
              if (res.status == 200) {
                this.$message({
                  message: res.data.msg,
                  type: 'success'
                });
                this.MgetScriptDictList()
                this.dialog1 = false

              }
            })
          }

          if (this.dialog1Title == "编辑项目") {
            this.ruleForm.tempname = undefined
            this.ruleForm.is_dir = undefined
            this.ruleForm.g6 = undefined
            AddDirectory(this.ruleForm).then(res => {
              if (res.status == 200) {
                this.$message({
                  message: res.data.msg,
                  type: 'success'
                });
                this.MgetScriptDictList()
                this.dialog1 = false

              }
            })
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //调度
    MRunflow(e) {
      runFlow({ id: e.id }).then(res => {
        if (res.status == 200) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
        }
      })
    },
    //查看
    MSee(e) {
      flowDetail({ execid: e.execid, id: e.id, offset: "0" }).then(res => {
        if (res.status == 200) {


          this.Cg6 = res.data.data.g6
          this.Seedata = res.data.data
          setTimeout(() => {
            console.log(this,"MMMMMMMMMMMMMMMMMMMMMMMM");
            this.$eventBus.$emit('sendG6', this.Cg6)
          }, 1);

          this.dialog2 = true
        }
      })
    },
    //发布
    Mfabu(e) {
      releaseProjectZip({ id: e.id }).then(res => {
        if (res.status == 200) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
        }
      })
    },
    //保存文件
    submitForm2() {

    }
    // createFile


  }
}
</script>

<style scoped>
 /deep/.el-card__body {
  padding: 0px !important;
}
.box-card {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: rgb(244, 245, 246);
}

.listBox {
  /* height: 70vh; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  /* margin-top: 20px; */
  /* border: 1px solid black; */
}

.box1 {
  width: 30%;
  margin: 15px;
  display: flex;
}

.textbt {
  font-size: 16px;
  color: blue;
  cursor: pointer;
}
</style>
